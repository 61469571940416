import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Auth } from '@/components/utils/Auth';
import { useSelf } from '@/hooks/useSelf';
import { NextPageWithLayout } from '@/types/next';
import { pagesPath } from '@/utils/$path';

const Page: NextPageWithLayout = () => {
  const router = useRouter();

  const { self } = useSelf();

  useEffect(() => {
    if (self?.user) {
      router.replace(pagesPath.home.$url());
    }
    // mount時のみ処理
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

Page.getLayout = page => <Auth>{page}</Auth>;

export default Page;
